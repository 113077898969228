<template>
  <div class="page-other page-content">
    <KlubbaRichText class="texts">
      <p><b>{{ progress.title }}</b></p>
      <p>{{ progress.description }}</p>
    </KlubbaRichText>

    <div class="container">
      <div class="page-other-card">
        <div class="page-other-section" v-for="(other, index) in others" :key="index" :class="{ 'is-loading': loading }">
          <p class="page-other-label">{{ other.title }}</p>
          <template v-if="other.type === 'select'">
            <klubba-select
              :options="other.options"
              v-model="other.answer"
            />
          </template>
          <div v-if="others.length !== (index + 1)" class="page-other-line" />
        </div>

        <KlubbaLoaderIcon :active="loading" />
      </div>
    </div>

    <div class="button-wrap">
      <klubba-footer
        backButton="Back"
        @goPrevPage="prevPage"
        @goNextPage="nextPage"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import KlubbaFooter from '@/components/layout/KlubbaFooter.vue';
import KlubbaSelect from '@/components/default/KlubbaSelect.vue';
import KlubbaRichText from '../../components/default/KlubbaRichText.vue';
import firebaseApp from '../../config/firebase';
import KlubbaLoaderIcon from '../../components/default/KlubbaLoaderIcon.vue';

export default {
  components: {
    KlubbaLoaderIcon,
    KlubbaRichText,
    KlubbaFooter,
    KlubbaSelect,
  },
  data() {
    return {
      loading: false,
      progress: {
        title: 'Is there anything else to share?',
        description: 'The more information you share, the faster you can add new subscriptions.',
      },
      others: [
        {
          type: 'select',
          title: 'Gender',
          answer: 'Male',
          options: ['Male', 'Female', 'Rather not say'],
        },
      ],
    };
  },
  mounted() {
    this.others[0].answer = this.$store.getters.getUserKey('gender') ?? 'Male';
  },
  computed: {
    club() {
      return this.$store.getters.club;
    },
    firebaseUser() {
      return this.$store.getters.firebaseUser;
    },
  },
  methods: {
    prevPage() {
      this.$router.back();
    },
    async nextPage() {
      const other = {
        gender: this.others[0].answer,
      };

      // Save info
      this.loading = true;
      const db = firebaseApp.firestore();
      await this.$store.commit('setNewUser', other);

      const userQuery = await db.collection('users').doc(this.firebaseUser.uid).get();
      if (userQuery.exists) {
        // Update firebase
        await db.collection('users').doc(this.firebaseUser.uid).update(this.$store.getters.newUser)
          .then(() => {
            console.log('Firebase doc in users collection updated');
            this.$router.push(`/${this.club.id}/notifications`);
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } else {
        this.loading = false;
        this.$router.push(`/${this.club.id}/notifications`);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.page-other-card
  @apply bg-white rounded-radius-10 p-5 relative

.page-other-section
  @apply block
  transition: opacity var(--transition)
  &.is-loading
    opacity: .3

.page-other-label
  @apply text-sm text-black-light leading-4 tracking-spacing-7 mb-5

.check-class
  @apply mb-5

.page-other-line
  @apply my-5 border border-background-light

.button-wrap
  @apply p-8 mt-auto

.texts
  @apply p-8

.container
  @apply px-8
</style>
