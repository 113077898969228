<template>
  <div class="section-select">
    <select
      ref="select"
      :value="modelValue"
      @input="handleInput"
    >
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option"
      >
        {{option}}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: ['options', 'modelValue'],
  methods: {
    handleInput() {
      this.$emit('update:modelValue', this.$refs.select.value);
    },
  },
};
</script>

<style lang="sass" scoped>
select
  @apply focus:outline-none w-full p-0 appearance-none text-left pr-4
  background: transparent
  background-image: url("~@/assets/icons/caret-down.svg")
  background-repeat: no-repeat
  background-position: right center
</style>
